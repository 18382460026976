<template>
  <div class="card-trainer">
    <div class="card-trainer__body">
      <div class="card-trainer__img">
        <template v-if="img">
          <img :src="img" alt="photo">
        </template>
        <template v-else>
          <svg width="70" height="57" viewBox="0 0 70 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'card-trainer__ava'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.907 2.48a2.25 2.25 0 011.636-.705h16.354a2.25 2.25 0 011.663.735l5.852 6.423a3.75 3.75 0 002.773 1.225h12.534a2.25 2.25 0 012.25 2.25v40.41a2.25 2.25 0 01-2.25 2.25H4.28a2.25 2.25 0 01-2.25-2.25v-40.41a2.25 2.25 0 012.25-2.25h11.755a3.75 3.75 0 002.725-1.175l6.146-6.503zM26.543.275a3.75 3.75 0 00-2.726 1.174l-6.146 6.504a2.25 2.25 0 01-1.635.704H4.28a3.75 3.75 0 00-3.75 3.75v40.41a3.75 3.75 0 003.75 3.75H65.72a3.75 3.75 0 003.75-3.75v-40.41a3.75 3.75 0 00-3.75-3.75H53.185a2.25 2.25 0 01-1.664-.734L45.67 1.499A3.75 3.75 0 0042.897.275H26.543zm.63 29.542a7.548 7.548 0 1115.096 0 7.548 7.548 0 01-15.096 0zm7.548-9.047a9.048 9.048 0 100 18.095 9.048 9.048 0 000-18.095z" fill="#DEE4ED"/></svg>
        </template>
      </div>
      <div class="card-trainer__content">
        <div class="card-trainer__name">{{ name }}</div>
        <div class="card-trainer__tags">
          <template v-for="item in tags">
            <div class="card-trainer__tags-ell" :key="item.index">{{ item }}</div>
          </template>
        </div>
      </div>
      <div class="card-trainer__control" @click="$emit('remove')">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:class="'card-trainer__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardTrainer",
  props: {
    img: String,
    name: String,
    tags: Array,
  }
};
</script>

<style lang="scss" scoped>

// card-trainer
.card-trainer {
  position: relative;
  height: 100%;
  padding: 12px 16px;
  background-color: var(--br-block-prime);
  border-radius: 8px;
  cursor: pointer;
  .card-trainer__body {
    display: flex;
    margin-bottom: 10px;
    .card-trainer__img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 72px;
      height: 72px;
      margin-right: 16px;
      border-radius: 8px;
      background-color: var(--br-block-sub);
      overflow: hidden;
      .card-trainer__ava {
        width: 40px;
        height: auto;
        path {
          fill: var(--text-descr);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-trainer__content {
      .card-trainer__name {
        margin-bottom: 6px;
        color: var(--text-prime);
        font-size: 17px;
      }
    }
  }
  .card-trainer__control {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    .card-trainer__icon {
      margin-right: 5px;
      cursor: pointer;
      path {
        fill: var(--color-prime);
        transition: all .2s ease-in-out;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        path {
          fill: var(--color-sub);
        }
      }
    }
  }
  .card-trainer__tags {
    display: flex;
    flex-wrap: wrap;
    .card-trainer__tags-ell {
      display: block;
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 6px 8px 4px;
      color: var(--color-prime);
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      border: 1px solid #777971;
      border-radius: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &:hover {
    .card-trainer__control {
      opacity: 1;
      visibility: visible;
    }
  }
}

</style>
