<template>
  <ClubTemplate :clubId="clubId">
    <div class="row">
      <div class="col-5">
        <FveSelectAjaxV2Trainer
          label="Добавить тренера"
          v-model="trainer"
        />
      </div>
      <div class="col-5">
        <FveMultiSelect
          label="Площадка"
          v-model="place"
          :options="placeList"
          :optionFieldId="'id'"
          :optionFieldName="'name'"
        />
      </div>
      <div class="col-2">
        <div class="btn-gp" style="padding-top: 24px;">
          <b-button size="lg" variant="danger" @click="addTrainer">Добавить</b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <template v-for="(item, index) in trainerList">
        <div class="col-4 mb-sm" :key="index">
          <router-link :to="{ name: $routeName.TRAINER_EDIT, params: { trainerId: item.id }}" class="card-link">
            <CardTrainer
              :img="item.img"
              :name="item.name"
              :tags="item.tags"
              @remove="removeTrainer(item, index)"
            />
          </router-link>
        </div>
      </template>
    </div>
    <b-pagination
      v-if="pageCount > pageLimit"
      v-model="pageCurrent"
      :total-rows="pageCount"
      :per-page="pageLimit"
      @change="paginationChange"

      aria-controls="table"
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>
  </ClubTemplate>
</template>

<script>

import ClubTemplate from './_component/ClubTemplate.vue';
import CardTrainer from "@component/Module/CardTrainer";

import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";
// import FveSelectObject        from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";
import FveMultiSelect         from "@widgetFormValidate/src/Element/MultiSelect/FveMultiSelect";

export default {
  components: {
    ClubTemplate,
    CardTrainer,
    FveSelectAjaxV2Trainer,
    // FveSelectObject,
    FveMultiSelect,
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      pageCurrent: 1,
      pageLimit: 12,
      pageCount: null,
      trainer: null,
      trainerList: [],
      //
      place: [],
      placeList: [],
    };
  },
  methods:{
    load(){

      RequestManager.Club.getClubRoomList({
        clubId: this.clubId,
      }).then( (response) => {
        this.placeList = response.results;
      });

      RequestManager.Club.getClubTrainerList({
        clubId: this.clubId,
      }).then( (itemList) => {
        this.pageCount = itemList.count;
        this.trainerList = itemList.results.map((item) => {
          return {
            id        : item.id,
            img       : item.avatar,
            name      : `${item.last_name} ${item.first_name} ${item.middle_name}`,
            tags      : item.tags.map(tagObject => tagObject.tag),
          };
        });
      });
    },

    reload(){
      this.place        = [];
      this.trainer      = null;
      this.trainerList  = [];
      this.load();
    },

    addTrainer() {
      // RequestManager.Club.addClubTrainer({
      //   clubId: this.clubId,
      //   trainerId: this.trainer ? this.trainer.id : null,
      // }).then(this.reload);

      if(!this.place || !this.place.length || !this.trainer) {
        return;
      }
      let promiseArr = [];
      promiseArr.push(Promise.resolve({}));
      for(let i = 0; i < this.place.length; i++) {
        let promise = RequestManager.Trainer.addPlace({
          trainerId : this.trainer ? this.trainer.id : null,
          placeId   :  this.place[i] ?  this.place[i].id : null,
        });

        promiseArr.push(promise);
      }

      Promise.all(promiseArr).then(this.reload);
    },
    removeTrainer(trainer){
      const options = {title: 'Удалить связь тренера с клубом?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить связь тренера с клубом?', options)
        .then((res) => {
          if(res && res.ok) {
            RequestManager.Club.deleteClubTrainer({
              clubId: this.clubId,
              trainerId: trainer.id,
            }).then(() => {
              this.$toasts.push({
                message: 'Тренер успешно удален',
                duration: 4000,
              });
              this.reload();
            }, () => {});
          }
        });
    },
    paginationChange(val) {
      this.pageCurrent = val;
      this.load();
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
// fix row
.row {
  margin-left: -8px;
  margin-right: -8px;
  .col-4, .col-8 {
    padding: 0 8px;
  }
}
</style>

